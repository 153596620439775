import { motion } from "framer-motion";
import { dropIn } from "../utils/animations";

const Wrapper = ({ children,show }) => {
  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit">
      <div className="wrapper position-relative rounded text-center px-3 px-xl-6 pt-5 pb-4">
        {children}
      </div>
    </motion.div>
  );
};

export default Wrapper;
