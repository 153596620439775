import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import importImages from "../utils/importImages";
import Wrapper from "./Wrapper";

// Import all images in image folder
const images = importImages(
  require.context("../assets/images/icons/", false, /\.(gif|jpe?g|svg)$/)
);

function Users() {
  const config = {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  };

  const { game } = useParams();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (game === "words") {
      const getLeaderBoard = async () => {
        try {
          const response = await axios.get(
            "http://lidl-izziv.brainylab.io/api/games/lidlhr/words/leader-board",
            config
          );
          setData(response.data);
          console.log(response.data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getLeaderBoard();
    } else if (game === "flags") {
      const getLeaderBoard = async () => {
        try {
          const response = await axios.get(
            "http://lidl-izziv.brainylab.io/api/games/lidlhr/flags/leader-board",
            config
          );
          setData(response.data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getLeaderBoard();
    } else if (game === "quiz") {
      const getLeaderBoard = async () => {
        try {
          const response = await axios.get(
            "http://lidl-izziv.brainylab.io/api/games/lidlhr/quiz/leader-board",
            config
          );
          setData(response.data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getLeaderBoard();
    } else if (game === "memory") {
      const getLeaderBoard = async () => {
        try {
          const response = await axios.get(
            "http://lidl-izziv.brainylab.io/api/games/lidlhr/tower/leader-board",
            config
          );
          setData(response.data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getLeaderBoard();
    }

    if (!loading) {
      document.querySelector(".table-active").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }

    document.body.className = "page-" + game;
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <>
      {!loading && data && game === "words" ? (
        <>
          <h1 className="d-none d-md-block">Lestvica najboljših</h1>

          <div className="leaderboard mt-n5 ms-n3 me-n3 mt-md-0 ms-md-n3 me-md-0 mb-4">
            <div className="">
              <div className="table-container">
                <table className="table small">
                  <thead>
                    <tr>
                      <th scope="col">Mesto</th>
                      <th scope="col">Vzdevek</th>
                      <th scope="col">Ime in priimek</th>
                      <th scope="col">Točke</th>
                      <th scope="col">Začetek</th>
                      <th scope="col">Konec</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data).map((item, i) => (
                      <tr
                        className={
                          data[item].user.current_user && "table-active"
                        }
                        key={data[item].id}>
                        <th scope="row">{i + 1}</th>
                        <td>
                          {data[item].user.nickname !== null
                            ? data[item].user.nickname
                            : data[item].user.name}
                        </td>
                        <td>{data[item].user.name} </td>

                        <td>{data[item].points} </td>
                        <td>{data[item].started_on} </td>
                        <td>{data[item].finished_on} </td>
                        <td>{data[item].user.email} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <h1>Lidlove vrednote</h1>
          <div className="content-grid pt-3 pt-md-5">
            <div className="row g-5">
              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img
                      className="pb-2"
                      src={images["icon-vrednote-uspesnost.svg"]}
                    />
                    <h2>
                      Vrednota:
                      <br />
                      USPEŠNOST
                    </h2>
                  </div>

                  <p className="p-3">
                    K uspehu prispevam po svojih najboljših močeh, v delo vlagam
                    znanje in energijo. Čutim zadovoljstvo ob uspehih in sem del
                    uspeha. Izzivov se lotevam pogumno in ustvarjam poligon
                    ustvarjalnosti. Čutim se vključenega v tim, izkoriščam svoj
                    potencial in rastem.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img
                      className="pb-2"
                      src={images["icon-vrednote-postenost.svg"]}
                    />
                    <h2>
                      Vrednota:
                      <br />
                      POŠTENOST
                    </h2>
                  </div>

                  <p className="p-3">
                    Iskren sem do sebe, sodelavcev in vodij. Sam sebi priznam,
                    kje so moje pomanjkljivosti, do sodelavcev sem objektiven,
                    svojemu vodji iskreno povem njegove pomanjkljivosti pri
                    delu, ki ovirajo moje delovanje. Na ta način ustvarjam
                    okolje zaupanja, spodbujam izmenjavo povratnih informacij.
                    Gradimo mostove zaupanja.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img
                      className="pb-2"
                      src={images["icon-vrednote-povezanost.svg"]}
                    />
                    <h2>
                      Vrednota:
                      <br />
                      POVEZANOST
                    </h2>
                  </div>

                  <p className="p-3">
                    Prispevam k uspehu celotne ekipe pri uresničevanju skupnega
                    cilja in se veselim naših uspehov. Na ta način prispevam k
                    timskemu duhu, povezanosti in pozitivni energiji. V timu
                    prevzemam aktivno vlogo. Če sem aktiven, aktivno sooblikujem
                    spremembe, iščem rešitve in povezujem tim.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img
                      className="pb-2"
                      src={images["icon-vrednote-dinamicnost.svg"]}
                    />
                    <h2>
                      Vrednota:
                      <br />
                      DINAMIČNOST
                    </h2>
                  </div>

                  <p className="p-3">
                    Predlagam spremembe, ki so lahko priložnost za izboljšave za
                    podjetje in moj osebni razvoj. Tako je delo lažje za vse,
                    pri tem pa narašča tudi moje osebno zadovoljstvo.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img
                      className="pb-2"
                      src={images["icon-vrednote-skrbnost.svg"]}
                    />
                    <h2>
                      Vrednota:
                      <br />
                      SKRBNOST
                    </h2>
                  </div>

                  <p className="p-3">
                    Poskrbim, da ima kupec ali poslovni partner prijetno
                    izkušnjo z mano. Zavedam se pomembnosti odnosa kupca in
                    poslovnega partnerja ter s tem skrbim za dolgoročno
                    sodelovanje.
                  </p>
                </div>
              </div>
            </div>
            <Link className="" to="/games">
              <button className="btn mt-5">NA PRVO STRAN</button>
            </Link>
          </div>
        </>
      ) : !loading && data && game === "flags" ? (
        <Wrapper>
          <h1 className="d-none d-md-block">Lestvica najboljših</h1>

          <div className="leaderboard mt-n5 ms-n3 me-n3 mt-md-0 ms-md-n3 me-md-0 mb-4">
            <div className="p-md-5">
              <div className="table-container">
                <table className="table small">
                  <thead>
                    <tr>
                      <th scope="col">Mesto</th>
                      <th scope="col">Vzdevek</th>
                      <th scope="col">Ime in priimek</th>
                      <th scope="col">Točke</th>
                      <th scope="col">Začetek</th>
                      <th scope="col">Konec</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data).map((item, i) => (
                      <tr
                        className={
                          data[item].user.current_user && "table-active"
                        }
                        key={data[item].id}>
                        <th scope="row">{i + 1}</th>
                        <td>
                          {data[item].user.nickname !== null
                            ? data[item].user.nickname
                            : data[item].user.name}
                        </td>
                        <td>{data[item].user.name} </td>

                        <td>{data[item].points} </td>
                        <td>{data[item].started_on} </td>
                        <td>{data[item].finished_on} </td>
                        <td>{data[item].user.email} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <h1>Lidlove ugodnosti za zaposlene</h1>
          <p>
            Več o Lidlovih ugodnosti za zaposlene si lahko prebereš na intranetu
            in aplikaciji We are Lidl pod zavihkom »15 let zame«.
          </p>
          <div className="content-grid pt-3 pt-md-5">
            <div className="row g-5">
              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["15_eur.svg"]} />
                    <h2>15 eur ob rojstnem dnevu</h2>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["letovanje.svg"]} />
                    <h2>Letovanje otrok od 6 do 12 let</h2>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img
                      className="pb-2"
                      src={images["tabor_za_nastnike.svg"]}
                    />
                    <h2>
                      Tabor za
                      <br /> najstnike
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img
                      className="pb-2"
                      src={images["novoletno_obdarovanje.svg"]}
                    />
                    <h2>Novoletno obdarovanje otrok</h2>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img
                      className="pb-2"
                      src={images["novoletna_druzenja.svg"]}
                    />
                    <h2>Novoletna druženja za zaposlene</h2>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["paket.svg"]} />
                    <h2>Paket za bodočo mamico in novorojenčka</h2>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["piknik.svg"]} />
                    <h2>
                      Piknik za
                      <br />
                      zaposlene
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["jubilejna.svg"]} />
                    <h2>Jubilejne nagrade za zaposlene</h2>
                  </div>
                </div>
              </div>
            </div>
            <Link className="" to="/games">
              <button className="btn mt-5">NA PRVO STRAN</button>
            </Link>
          </div>
        </Wrapper>
      ) : !loading && data && game === "quiz" ? (
        <Wrapper>
          <h1 className="d-none d-md-block">Lestvica najboljših</h1>

          <div className="leaderboard mt-n5 ms-n3 me-n3 mt-md-0 ms-md-n3 me-md-0 mb-4">
            <div className="p-md-5">
              <div className="table-container">
                <table className="table small">
                  <thead>
                    <tr>
                      <th scope="col">Mesto</th>
                      <th scope="col">Vzdevek</th>
                      <th scope="col">Ime in priimek</th>
                      <th scope="col">Točke</th>
                      <th scope="col">Začetek</th>
                      <th scope="col">Konec</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data).map((item, i) => (
                      <tr
                        className={
                          data[item].user.current_user && "table-active"
                        }
                        key={data[item].id}>
                        <th scope="row">{i + 1}</th>
                        <td>
                          {data[item].user.nickname !== null
                            ? data[item].user.nickname
                            : data[item].user.name}
                        </td>
                        <td>{data[item].user.name} </td>

                        <td>{data[item].points} </td>
                        <td>{data[item].started_on} </td>
                        <td>{data[item].finished_on} </td>
                        <td>{data[item].user.email} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <h1>Ustvarimo boljši svet</h1>

          <div className="content-grid pt-3 pt-md-5">
            <div className="row g-5">
              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Podpora organizacij in društev</h2>
                  </div>
                  <p className="p-3">
                    V poslovnem letu 2021 smo z donacijami pomagali kar 172
                    različnim društvom in organizacijam.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>
                      Donirana
                      <br />
                      hrana
                    </h2>
                  </div>
                  <p className="p-3">
                    V poslovnem letu 2021 smo donirali 244 ton hrane iz
                    poslovalnic, ki so vključene v projekt donirana hrana.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Koraki za tačke in brez pirotehnike</h2>
                  </div>
                  <p className="p-3">
                    Na Lidlovih policah že 3 leta (od leta 2019 dalje) ne
                    prodajamo pirotehnike.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Ponosno podpiramo parašportnike</h2>
                  </div>
                  <p className="p-3">
                    Parašportniki nam iz dneva v dan dokazujejo, da šport ne
                    pozna oviranosti. Na minulih paralimpijskih igrah v Tokiu so
                    osvojili že 51. medaljo po vrsti. Lidl Slovenija jih s
                    ponosom podpira kot del Zveze za šport invalidov – Slovenski
                    paralimpijski komite (Zveza ŠIS-SPK).
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>
                      Trajnostno
                      <br />
                      delovanje
                    </h2>
                  </div>
                  <p className="p-3">
                    V Lidlu Slovenija je skrb za okolje eden od stebrov
                    trajnostnega delovanja, zato poskušamo področje varstva
                    naravnih virov zajeti celostno. Če letni prihranek emisij
                    CO2, ob pretekli postavitvi 11 sončnih elektrarn na naših
                    poslovalnicah, pretvorimo v število dreves, ki pretvorijo
                    ogljikov dioksid v kisik, potrebujemo približno 26.000
                    dreves.
                  </p>
                </div>
              </div>
            </div>
            <Link className="" to="/games">
              <button className="btn mt-5">NA PRVO STRAN</button>
            </Link>
          </div>
        </Wrapper>
      ) : !loading && data && game === "memory" ? (
        <Wrapper>
          <h1 className="d-none d-md-block">Lestvica najboljših</h1>

          <div className="leaderboard mt-n5 ms-n3 me-n3 mt-md-0 ms-md-n3 me-md-0 mb-4">
            <div className="p-md-5">
              <div className="table-container">
                <table className="table small">
                  <thead>
                    <tr>
                      <th scope="col">Mesto</th>
                      <th scope="col">Vzdevek</th>
                      <th scope="col">Ime in priimek</th>
                      <th scope="col">Točke</th>
                      <th scope="col">Začetek</th>
                      <th scope="col">Konec</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data).map((item, i) => (
                      <tr
                        className={
                          data[item].user.current_user && "table-active"
                        }
                        key={data[item].id}>
                        <th scope="row">{i + 1}</th>
                        <td>
                          {data[item].user.nickname !== null
                            ? data[item].user.nickname
                            : data[item].user.name}
                        </td>
                        <td>{data[item].user.name} </td>

                        <td>{data[item].points} </td>
                        <td>{data[item].started_on} </td>
                        <td>{data[item].finished_on} </td>
                        <td>{data[item].user.email} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <h1>Lidlove aktivnosti za zaposlene</h1>

          <div className="content-grid pt-3 pt-md-5">
            <div className="row g-5">
              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Sadni dan</h2>
                  </div>
                  <p className="p-3">
                    Sadni dan za vse Lidlovke in Lidlovce je od tega poslovnega
                    leta dalje dvakrat mesečno.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>24 urno brezplačno psihološko svetovanje</h2>
                  </div>
                  <p className="p-3">
                    V sklopu aktivnosti na področju mentalnega zdravja je od
                    tega poslovnega leta dalje nudenje strokovne psihološke
                    pomoči zunanjega psihologa na voljo kadarkoli – 24 ur na
                    dan, vse dni v tednu. Brezplačno, anonimno, za Lidlovke in
                    Lidlovce ter ožje družinske člane.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Dan ambasadorjev zdravja</h2>
                  </div>
                  <p className="p-3">
                    Za ambasadorje zdravja, ki so predstavniki poslovalnic in
                    oddelkov je organiziran dan, kjer pridobijo dodatna
                    strokovna znanja, ideje in nasvete s strani strokovnjakov iz
                    področja medicine, športa in medosebnih odnosov.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Udeležba na Ljubljanskem maratonu</h2>
                  </div>
                  <p className="p-3">
                    Zaposlenim, ki izrazijo željo, plačamo startnino na
                    Ljubljanskem maratonu. Prav tako poskrbimo za dodatno
                    prepoznavnost ekipe, skupno ogrevanje in navijaško podporo.
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Slovenski zajtrk</h2>
                  </div>
                  <p className="p-3">
                    V sklopu obeležitve tradicionalne slovenskega zajtrka,
                    poskrbimo za uravnotežen zajtrk, ki vsebuje slovensko maslo,
                    med, kruh, čaj in jabolko.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Roza oktober</h2>
                  </div>
                  <p className="p-3">
                    Oktobra pozornost namenimo Lidlovkam in pomenu
                    samopregledovanja in skrbi zase. Pripravljamo raznolika
                    predavanja in poskrbimo za ugodnejši ultrazvok dojk v
                    Ljubljani in Mariboru.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Brkati november</h2>
                  </div>
                  <p className="p-3">
                    O pomenu samopregledovanja, prepoznavanju znakov bolezni in
                    možnosti preprečevanja posebej aktivno obveščamo Lidlovce v
                    novembru.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-4">
                <div className="item rounded">
                  <div className="heading py-4 rounded-top">
                    <img className="pb-2" src={images["achivment.svg"]} />
                    <h2>Miško</h2>
                  </div>
                  <p className="p-3">
                    Mišično-kostna obolenja so najpogostejši vzrok zdravstvenih
                    težav po vsem svetu. Pri kostno-mišičnih obolenjih,
                    povezanih z delom, gre za okvare mišic, sklepov, kit, vezi,
                    živcev, kosti in perifernega krvnega obtoka. Da bi zmanjšali
                    tovrstne težave pri zaposlenih v poslovalnicah, smo v marcu
                    začeli s projektom Miško.
                  </p>
                </div>
              </div>
            </div>
            <Link className="" to="/games">
              <button className="btn mt-5">NA PRVO STRAN</button>
            </Link>
          </div>
        </Wrapper>
      ) : (
        <span></span>
      )}
    </>
  );
}

export default Users;
