import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import useSound from "use-sound";

import axios from "axios";
import Success from "../Success";
import GameStatus from "../GameStatus";
import Wrapper from "../Wrapper";
import soundPop from "../../assets/sounds/pop.mp3";
import soundWin from "../../assets/sounds/win.mp3";

const questions = [
  {
    questionText:
      "Koliko različnih društev in (lokalnih) organizacij smo z donacijami podprli v poslovnem letu 2021?",
    questionAnswer: "podprtih organizacij",
    questionMin: 50,
    questionMax: 250,
    questionCorrect: 172,
    default: 50,
    labels: { 50: "50", 250: "250" },
  },
  {
    questionText:
      "Koliko ton hrane smo v poslovnem letu 2021 donirali iz naših poslovalnic, ki so vključene v projekt donirana hrana?",
    questionAnswer: "ton donirane hrane",
    questionMin: 100,
    questionMax: 400,
    questionCorrect: 244,
    default: 100,
    labels: { 100: "100", 400: "400" },
  },
  {
    questionText:
      "Koliko let na Lidlovih policah ne prodajamo več pirotehnike?",
    questionAnswer: "leta brez pirotehnike",
    questionMin: 1,
    questionMax: 6,
    questionCorrect: 3,
    default: 1,
    labels: { 1: "1", 6: "6" },
  },
  {
    questionText:
      "V Lidlu Slovenija smo ponosni podpornik Zveze za šport invalidov – Slovenski paralimpijski komite (Zveza ŠIS-SPK). Katero medaljo po vrsti so osvojili na minulih paralimpijskih igrah v Tokiu?",
    questionAnswer: ". medalja po vrsti",
    questionMin: 10,
    questionMax: 120,
    questionCorrect: 51,
    default: 10,
    labels: { 10: "10", 120: "120" },
  },
  {
    questionText:
      "Koliko bo znašal letni prihranek emisij CO2, ob pretekli postavitvi 11 sončnih elektrarn na naših poslovalnicah, če ga pretvorimo v število dreves, ki absorbirajo oz. pretvorijo ogljikov dioksid v kisik? ",
    questionAnswer: "CO2 emisij manj",
    questionMin: 5000,
    questionMax: 50000,
    questionCorrect: 26000,
    default: 0,
    labels: { 0: "0", 50000: "50.000" },
  },
];
const calculateScore = (correct, input, max) => {
  let maxStepScore = 100;

  if (input > correct) {
    let result = Math.ceil(((input - correct) * 100) / max);
    return maxStepScore - result;
  }

  if (input < correct) {
    let result = Math.ceil(((correct - input) * 100) / max);
    return maxStepScore - result;
  }

  if (input === correct) {
    return maxStepScore;
  }
};

function Quiz({ id }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [score, setScore] = useState(0);
  const [stepScore, setStepScore] = useState({
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
  });

  const [sliderValue, setSliderValue] = useState();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [finish, setFinish] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [counter, setCounter] = useState(3);
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pop] = useSound(soundPop, { volume: 1 });
  const [win] = useSound(soundWin, { volume: 0.8 });

  const config = {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  };

  const createGame = async () => {
    try {
      const response = await axios.post(
        "http://lidl-izziv.brainylab.io/api/games/lidlhr/quiz",
        null,
        config
      );

      setData(response.data);
    } catch (error) {
      setError(error.response.data.error);
    } finally {
    }
  };

  const updateGame = async () => {
    const dataScore = {
      points: score,
      completed: true,
    };

    try {
      const response = await axios.put(
        "http://lidl-izziv.brainylab.io/api/games/lidlhr/quiz/" + data.id,
        dataScore,
        config
      );
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleAnswerOptionClick = () => {
    let updatedValue = {};
    updatedValue = { [currentQuestion]: sliderValue };
    setStepScore((stepScore) => ({
      ...stepScore,
      ...updatedValue,
    }));

    const partScore = calculateScore(
      questions[currentQuestion].questionCorrect,
      sliderValue,
      questions[currentQuestion].questionMax
    );

    //setScore(score + partScore);
    setScore(score + partScore - Math.round(time / 1500));
    pop();

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setFinish(true);
    }
  };

  const goToSuccess = () => {
    updateGame();
    setSuccess(true);
    win();
  };

  const handleChangeSlider = (value) => {
    setSliderValue(value);
  };

  const Countdown = () => {
    return (
      <div>
        <h1>{counter}</h1>
      </div>
    );
  };

  useEffect(() => {
    setShowCounter(true);
    createGame();
  }, []);

  useEffect(() => {}, [sliderValue]);

  useEffect(() => {
    setSliderValue(questions[currentQuestion].default);
  }, [currentQuestion]);

  useEffect(() => {
    let interval = null;
    if (!finish && showCounter === false) {
      interval = setInterval(() => {
        setTime((time) => time + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [finish, showCounter]);

  useEffect(() => {
    if (counter === 0) {
      setShowCounter(false);
    }

    const timer =
      counter > 0 &&
      showCounter &&
      setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter, showCounter]);

  return (
    <AnimatePresence>
      {success ? (
        <Wrapper>
          <GameStatus score={score} time={time} name="Ustvarjamo boljši svet" />
          <Success score={score} time={time} id={id} />
        </Wrapper>
      ) : (
        <div className={loading ? "hide" : "show"}>
          <Wrapper>
            <GameStatus
              score={score}
              time={time}
              name="Ustvarjamo boljši svet"
            />
            <div className="game-words pt-5 pt-sm-5">
              {showCounter && (
                <div className="countdown d-flex align-items-center justify-content-center rounded">
                  <Countdown />
                </div>
              )}

              {error ? (
                <>
                  <h1 className="mt-5">Kviz ste že rešili.</h1>
                  <Link to={"/games/quiz/leaderboard"}>
                    <button className="btn btn-blue2 text-white mt-5 mb-md-0 mx-1  px-3 px-md-4">
                      Oglej si lestvico
                    </button>
                  </Link>
                </>
              ) : (
                <>
                  <motion.div
                    key={currentQuestion}
                    initial={{ opacity: 0, translateX: 0 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: 100 }}
                    transition={{ duration: 0.5, type: "tween" }}>
                    <div
                      className={`question-section ${
                        finish ? "finished" : ""
                      }`}>
                      <div className="question-count">
                        <span className="small">
                          Vprašanje {currentQuestion + 1}
                        </span>
                        /{questions.length}
                      </div>
                      <div className="question-text mt-2">
                        <p className="lead mb-0">
                          {questions[currentQuestion].questionText}
                        </p>
                      </div>
                    </div>
                    <div className="answer-section">
                      <div
                        className={`slider custom-labels ${
                          finish ? "finished" : ""
                        }`}></div>

                      {finish ? (
                        <button
                          onClick={goToSuccess}
                          className="btn btn-blue2 text-white mb-2 mb-md-0 mx-1  px-3 px-md-4">
                          Zaključi
                        </button>
                      ) : (
                        <button
                          onClick={() => handleAnswerOptionClick()}
                          className="btn btn-blue2 text-white mb-2 mb-md-0 mx-1  px-3 px-md-4">
                          Potrdi
                        </button>
                      )}
                    </div>
                  </motion.div>

                  <div id="rf-wordcontainer">
                    <ul className="list-inline">
                      {Object.keys(stepScore).map((keyName, i) => (
                        <>
                          <li
                            className={`list-inline-item text-center ${
                              currentQuestion > i || finish
                                ? "rf-foundword"
                                : ""
                            }`}
                            key={i}>
                            <span className="input-label">
                              <strong>{stepScore[keyName]}</strong>
                              <br />
                              <p className="text-green">
                                {questions[i].questionCorrect}{" "}
                                {questions[i].questionAnswer}
                              </p>
                            </span>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </Wrapper>
        </div>
      )}
    </AnimatePresence>
  );
}

export default Quiz;
