import { useEffect, useState, createContext } from "react";
import { Link } from "react-router-dom";

import Wrapper from "./Wrapper";
import imgWin from "../assets/images/win.svg";

function Success({ id }) {
  return (
    <>
      <img className="w-100 mt-6 mb-4" src={imgWin} />
      <h1 className="mb-4">Juhu, igra je končana!</h1>

      {id !== "quiz" && (
        <Link to={"/games/" + id}>
          <button className="btn m-2">Igraj ponovno</button>
        </Link>
      )}

      <Link to={"/games/" + id + "/leaderboard"}>
        <button className="btn m-2">Končaj igro</button>
      </Link>
    </>
  );
}

export default Success;
