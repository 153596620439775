import React from 'react';
import Game from './Game';
import Finish from './Finish';

import useOptions from '../../utils/useOptions';


function MemoryGame(props) {
  const [options, game, set, backgrounds] = useOptions();


  
  const component = {
    playing: (
      <Game id={props.id} {...set} {...options} {...game} />
      ),
    finished: (
      <Finish />
    ),
    options: (
      <Game id={props.id} {...set} {...options} {...game} />
    )
  }

  return component[game.status];
}

export default MemoryGame;
