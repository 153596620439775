export const bg = [
  'liquid-cheese',
  'protruding-squares',
  'wintery-sunburst',
  'spectrum-gradient',
  'radiant-gradient',
  'large-triangles',
  'hollowed-boxes',
  'geometric-intersection',
  'dragon-scales',
  'flat-mountains',
];

export const sources = {
  bg,
}

export default sources;