
import { useDispatch } from "react-redux";
import { showIntroReducer } from "../store/gameSlice";
import Wrapper from "./Wrapper";
import soundBG from "../assets/sounds/game.mp3";
import importImages from "../utils/importImages";


// Import all images in image folder
const images = importImages(
  require.context("../assets/images/", false, /\.(gif|jpe?g|svg)$/)
);


function Intro({ game, data }) {
  const dispatch = useDispatch();


  const handleClick = () => {

    dispatch(showIntroReducer(false));

  };

  


  return (
    <Wrapper>
      <p className="fw-bold mb-0">PRAVILA IGRE</p>
      <h1 className="mb-4">{data.headline}</h1>
      <p>​{data.intro}</p>
      <p style={{whiteSpace: "pre-line"}} className="fw-bold">{data.intro2}</p>


      <div className="row my-5">
        <div className="col-4">
          <img src={images["icon-"+data.id+"-usp-01.svg"]} />
          <p className="small pt-2">{data.usp1}</p>
        </div>
        <div className="col-4">
        <img src={images["icon-"+data.id+"-usp-02.svg"]} />
          <p className="small pt-2">{data.usp2}</p>
        </div>
        <div className="col-4">
        <img src={images["icon-"+data.id+"-usp-03.svg"]} />
          <p className="small pt-2">{data.usp3}</p>
        </div>
      </div>

      <button className="btn" onClick={handleClick}>
        ZAČNI IGRO
      </button>
    </Wrapper>
  );
}

export default Intro;
