function GameStatus({ time, score, name }) {
  return (
    <>
      <div className="status rounded">
        <div className="d-flex align-items-center">
          <div className="left">
            <div className="item item-score rounded">{score}</div>
          </div>
          <div className="d-flex w-100 headline align-items-center justify-content-center">
            <h3 className="text-white text-center mb-0">{name}</h3>
          </div>
          <div className="right">
            <div className="item item-time rounded">
              <div className="timer">
                <span className="digits">
                  {("" + Math.floor((time / 60000) % 60)).slice(-2)}:
                </span>
                <span className="digits">
                  {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GameStatus;
