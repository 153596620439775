import { useEffect, useState } from "react";
import $ from "jquery";
import { AnimatePresence, motion } from "framer-motion";
import useSound from "use-sound";

import axios from "axios";
import Success from "../Success";
import GameStatus from "../GameStatus";
import Wrapper from "../Wrapper";
import soundPop from "../../assets/sounds/pop.mp3";
import soundWin from "../../assets/sounds/win.mp3";

function Tower({ id }) {
  const [score, setScore] = useState(0);

  const [sliderValue, setSliderValue] = useState();
  const [data, setData] = useState();
  const [success, setSuccess] = useState(false);
  const [finish, setFinish] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [counter, setCounter] = useState(0);
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [play] = useSound(soundPop, { volume: 1 });
  const [win] = useSound(soundWin, { volume: 0.8 });

  const config = {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  };

  const createGame = async () => {
    try {
      const response = await axios.post(
        "http://lidl-izziv.brainylab.io/api/games/lidlhr/quiz",
        null,
        config
      );

      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const updateGame = async () => {
    const dataScore = {
      points: score,
      completed: true,
    };

    try {
      const response = await axios.put(
        "http://lidl-izziv.brainylab.io/api/games/lidlhr/quiz/" + data.id,
        dataScore,
        config
      );
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const Countdown = () => {
    return (
      <div>
        <h1>{counter}</h1>
      </div>
    );
  };

  useEffect(() => {
    let interval = null;
    if (!success && showCounter === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [success, showCounter]);

  useEffect(() => {
    if (counter === 0) {
      setShowCounter(false);
    }

    const timer =
      counter > 0 &&
      showCounter &&
      setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter, showCounter]);

  return (
    <AnimatePresence>
      {success ? (
        <Wrapper>
          <GameStatus score={score} time={time} name="Ustvarjamo boljši svet" />
          <Success score={score} time={time} id={id} />
        </Wrapper>
      ) : (
        <div className={loading ? "hide" : "show"}>
          <Wrapper>
            <GameStatus
              score={score}
              time={time}
              name="Ustvarjamo boljši svet"
            />
            <div className=" pt-5 pt-sm-5">
              {showCounter && (
                <div className="countdown d-flex align-items-center justify-content-center rounded">
                  <Countdown />
                </div>
              )}

              <h1>kajfsd</h1>
            </div>
          </Wrapper>
        </div>
      )}
    </AnimatePresence>
  );
}

export default Tower;
