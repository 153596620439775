import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showIntroReducer } from "../store/gameSlice";
import useSound from "use-sound";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { dropIn } from "../utils/animations";
import { AnimatePresence, motion } from "framer-motion";
import Wrapper from "./Wrapper";

import Words from "./Words/Words";
import Flags from "./Flags/Flags";
import Quiz from "./Quiz/Quiz";
import Tower from "./Tower/Tower";
import Memory from "./MemoryGame/MemoryGame";
import Intro from "./Intro";
import Leaderboard from "./Leaderboard";
import iconSoundYes from "../assets/images/icon-sound-yes.svg";
import iconSoundNo from "../assets/images/icon-sound-no.svg";

const data = {
  words: {
    id: "words",
    headline: "Disciplina 1: Križanka Lidlovih vrednot",
    intro:
      "​V Lidlu Slovenija trdno verjamemo v prednosti vedenja in vodenja, ki temelji na Lidlovih vrednotah. Ker pa nam je bolj kot napisano na papirju pomembno, da vrednote zares živimo pri našem vsakdanu, te pozivamo k sodelovanju pri njihovem uresničevanju.",
    intro2:
      "Poišči vseh 5 Lidlovih vrednot in se uvrsti na vrh lestvice najhitrejših.",
    usp1: "V križanki se skriva 5 besed oz. 5 vrednot našega podjetja.",
    usp2: "Hitreje kot najdeš besedo, več točk osvojiš.",
    usp3: "Ko najdeš besedo, z miško ali prstom povleci do zadnje črke.",
    starts: "2022-05-27T00:00:00+0200",
    ends: "2022-05-30T23:59:59+0200",
  },
  flags: {
    id: "flags",
    headline: "Disciplina 2: Zastave Lidlovih ugodnosti za zaposlene",
    intro:
      "​Kako spretno je tvoje oko in hitra tvoja roka? Poišči in poberi vseh osem zastavic Lidlovih ugodnosti za zaposlene preden ti zmanjka časa. Pozor! Pazi, da ene zastave ne pobereš dvakrat, saj ti ponovna izbira točke odvzame.",
    intro2:
      "Ujemi vseh 8 zastav Lidlovih ugodnosti za zaposlene in se uvrsti na lestvico najbolj spretnih Lidlovk in Lidlovcev.",
    usp1: "Pokaži kako hitri so tvoji prsti in ujemi zastave.",
    usp2: "Hitreje kot ujameš zastave, več točk osvojiš.",
    usp3: "Uvrsti se na vrh lestvice najboljših in osvoji nagrado.",
    starts: "2022-05-31T00:00:00+0200",
    ends: "2022-06-03T23:59:59+0200",
  },
  quiz: {
    id: "quiz",
    headline: "Disciplina 3: Ustvarjamo boljši svet",
    intro:
      "V Lidlu Slovenija se trudimo, da vsako naše dejanje izboljšuje naš interni svet, kot tudi svet, ki nas obdaja. S pozitivnim zgledom želimo vplivati na celotno družbo, saj smo skupaj najmočnejši. Korak za korakom skupaj krojimo mozaik k boljšemu svetu, zato te vabimo, da se na to pot skupaj z nami podaš tudi vi. Kako dobro se znajdeš pri poznavanju neverjetnih dejstev o našem ustvarjanju boljšega sveta? Bolj natančno kot ti uspe določiti vrednost na drsniku, večje število točk osvojiš. Pohiti, časa ni na pretek!",
    intro2:
      "Čim bolj natančno nastavi tehtnico in pravilno odgovori na vprašanje. \n To igro lahko igraš le enkrat. ",
    usp1: "Pozorno preberi vprašanje.",
    usp2: "Povleci drsnik čim bliže pravilnemu odgovoru.",
    usp3: "Bližje pravilnemu odgovoru kot nastaviš drsnik, več točk prejmeš.",
    starts: "2022-06-04T00:00:00+0200",
    ends: "2022-06-06T23:59:59+0200",
  },
  memory: {
    id: "memory",
    headline: "Disciplina 4: Spomin Promocije zdravja",
    intro:
      "Zaposleni so srce podjetja, njihovi odnosi in dobro počutje pa njegova duša. Pokaži svoje spretnosti in odkrij aktivnosti, ki vplivajo na boljše počutje in odnose na delovnem mestu. Hitreje kot jih najdeš, več točk dobiš.",
    intro2:
      "Odkrij vse pare spomina Promocije zdravja in se povzpni na vrh lestvice najhitrejših.",
    usp1: "Poišči pare osmih aktivnosti, ki jih ponuja naše podjetje.",
    usp2: "En par sestavljata slika in opis aktivnosti.",
    usp3: "Hitreje kot najdeš vse pare, več točk osvojiš.",
    starts: "2022-06-07T00:00:00+0200",
    ends: "2022-06-10T23:59:59+0200",
  },
};

function Game() {
  const { game } = useParams();
  const dispatch = useDispatch();
  const showIntro = useSelector((state) => state.game.showIntro);

  let navigate = useNavigate();

  const currentTime = new Date(Date.now()).getTime();
  const startTime = (game) => {
    let isoDate = game.starts;

    var date = new Date(isoDate).getTime();

    return date;
  };

  const endTime = (game) => {
    let isoDate = game.ends;

    var date = new Date(isoDate).getTime();

    return date;
  };

  const GameScreen = () => {
    if (game === "words" /* &&  (endTime(data.words) > currentTime) */) {
      return <Words id={game} />;
    } else if (game === "flags" /*  && (endTime(data.flags) > currentTime) */) {
      return <Flags id={game} />;
    } else if (game === "quiz" /* && (endTime(data.quiz) > currentTime) */) {
      return <Quiz id={game} />;
    } else if (game === "tower" /* && (endTime(data.tower) > currentTime) */) {
      return <Tower id={game} />;
    } else if (
      game === "memory" /* && (endTime(data.memory) > currentTime) */
    ) {
      return <Memory id={game} />;
    } else {
      alert("Čas se je iztekel.");
    }
  };

  useEffect(() => {
    dispatch(showIntroReducer(true));
    document.body.className = "page-" + game;

    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <>
      {showIntro ? (
        <div className="game-intro">
          <Intro data={data[game]} game={game} />
        </div>
      ) : (
        <div className="game-ingame">
          <GameScreen key={game} />
        </div>
      )}

      <div className="d-none">
        <Link to="/">Home</Link> | <Link to="/games">Games</Link>
      </div>
    </>
  );
}

export default Game;
