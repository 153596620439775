import React, { useEffect, useState } from "react";
import axios from "axios";
import useGame from "../../utils/useGame";
import PropTypes from "prop-types";
import useSound from "use-sound";
import { motion, AnimatePresence } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import Success from "../Success";
import GameStatus from "../GameStatus";
import Wrapper from "../Wrapper";
import soundPop from "../../assets/sounds/pop.mp3";
import soundWin from "../../assets/sounds/win.mp3";

var dateVariable = new Date();
var todayDay = dateVariable.getDate();

function Game(props, { id }) {
  const { bg, stop, finish } = props;

  const [score, setScore] = useState(0);

  const [cards, reshuffle, errors, finished, matches] = useGame(bg);
  const [data, setData] = useState();
  const [success, setSuccess] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [counter, setCounter] = useState(0);
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pop] = useSound(soundPop, { volume: 1 });
  const [win] = useSound(soundWin, { volume: 0.8 });

  const config = {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  };

  const createGame = async () => {
    try {
      const response = await axios.post(
        "http://lidl-izziv.brainylab.io/api/games/lidlhr/tower",
        null,
        config
      );

      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  /* 
  const updateGame = async () => {
    const dataScore = {
      points: score,
      completed: true,
    };

    try {
      const response = await axios.put(
        "http://lidl-izziv.brainylab.io/api/games/lidlhr/tower/" +
          data.id,
        dataScore,
        config
      );



      
    } catch (error) {
      console.log(error);
    } finally {
    }
  }; */

  const Countdown = () => {
    return (
      <div>
        <h1>{counter}</h1>
      </div>
    );
  };

  useEffect(() => {
    setLoading(false);
    setShowCounter(true);
    createGame();
  }, []);

  useEffect(() => {
    const dataScore = {
      points: score,
      completed: true,
    };

    if (success) {
      axios
        .put(
          "http://lidl-izziv.brainylab.io/api/games/lidlhr/tower/" + data.id,
          dataScore,
          config
        )
        .then((response) => console.log(response))
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }

    let interval = null;
    if (!success && showCounter === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [success, showCounter]);

  useEffect(() => {
    if (counter === 0) {
      setShowCounter(false);
    }

    const timer =
      counter > 0 &&
      showCounter &&
      setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter, showCounter]);

  useEffect(() => {
    if (finished) {
      setTimeout(() => {
        setSuccess(true);
        //updateGame();
      }, 500);
      stop();
      win();
    }
  }, [finished]);

  useEffect(() => {
    if (matches.length !== 0) {
      setScore(score + 100 - Math.round(time / 1500));
      pop();
    }
  }, [matches]);

  return (
    <AnimatePresence>
      {success ? (
        <Wrapper>
          <GameStatus
            score={score}
            time={time}
            name="Spomin Promocija zdravja"
          />
          <Success score={score} time={time} id={props.id} />
        </Wrapper>
      ) : (
        <div className={loading ? "hide" : "show"}>
          <Wrapper>
            <GameStatus
              score={score}
              time={time}
              name="Spomin Promocija zdravja"
            />
            <div className="pt-5 pt-sm-5">
              {showCounter && (
                <div className="countdown d-flex align-items-center justify-content-center rounded">
                  <Countdown />
                </div>
              )}

              <div className={"mt-sm-5 game"}>
                <div className="deck fadein px-3">{cards}</div>
              </div>
            </div>

            <div id="rf-wordcontainer">
              <ul className="list-inline d-flex justify-content-center">
                <>
                  <li
                    className={`list-inline-item text-center ${
                      matches.includes("brkatinovember") ? "rf-foundword" : ""
                    }`}>
                    <span className="input-label">
                      <strong>Brkati november</strong>
                    </span>
                  </li>
                  <li
                    className={`list-inline-item text-center ${
                      matches.includes("24urnosvetovanje") ? "rf-foundword" : ""
                    }`}>
                    <span className="input-label">
                      <strong>24 urno brezplačno svetovanje</strong>
                    </span>
                  </li>
                  <li
                    className={`list-inline-item text-center ${
                      matches.includes("danambasadorjevzdravja")
                        ? "rf-foundword"
                        : ""
                    }`}>
                    <span className="input-label">
                      <strong>Dan ambasadorjev zdravja</strong>
                    </span>
                  </li>
                  <li
                    className={`list-inline-item text-center ${
                      matches.includes("ljubljanskimaraton")
                        ? "rf-foundword"
                        : ""
                    }`}>
                    <span className="input-label">
                      <strong>Udeležba na Ljubljanskem maratonu</strong>
                    </span>
                  </li>
                  <li
                    className={`list-inline-item text-center ${
                      matches.includes("rozaoktober") ? "rf-foundword" : ""
                    }`}>
                    <span className="input-label">
                      <strong>Roza oktober</strong>
                    </span>
                  </li>
                  <li
                    className={`list-inline-item text-center ${
                      matches.includes("misko") ? "rf-foundword" : ""
                    }`}>
                    <span className="input-label">
                      <strong>Miško</strong>
                    </span>
                  </li>
                  <li
                    className={`list-inline-item text-center ${
                      matches.includes("sadnidan") ? "rf-foundword" : ""
                    }`}>
                    <span className="input-label">
                      <strong>Sadni dan</strong>
                    </span>
                  </li>
                  <li
                    className={`list-inline-item text-center ${
                      matches.includes("slovenskizajtrk") ? "rf-foundword" : ""
                    }`}>
                    <span className="input-label">
                      <strong>Slovenski zajtrk</strong>
                    </span>
                  </li>
                </>
              </ul>
            </div>
          </Wrapper>
        </div>
      )}
    </AnimatePresence>
  );
}

Game.propTypes = {
  bg: PropTypes.string.isRequired,
  stop: PropTypes.func.isRequired,
  finish: PropTypes.func.isRequired,
};

export default Game;
