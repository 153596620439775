import "./assets/styles/style.scss";
import { useEffect, useState } from "react";
import { Routes, Route, useLocation, Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import useSound from "use-sound";
import Home from "./games/Home";
import Games from "./games/Games";
import Game from "./games/Game";
import Intro from "./games/Intro";
import Leaderboard from "./games/Leaderboard";
import Picnic from "./games/Picnic";
import Users from "./games/Users";

import { ReactComponent as ImgSidebar } from "./assets/images/ilustracija.svg";
import { ReactComponent as ImgSidebarMobile } from "./assets/images/ilustracija-mobile.svg";
import imgLogo from "./assets/images/logo-sec.svg";
import imgLogoLidl from "./assets/images/logo-lidl.svg";

import soundBG from "./assets/sounds/game.mp3";
import soundPop from "./assets/sounds/pop.mp3";

function App() {
  const location = useLocation();
  const showIntro = useSelector((state) => state.game.showIntro);
  const isAuthenticated = true; //!!localStorage.getItem("token");
  const [sound, setSound] = useState(false);

  const [playPop] = useSound(soundPop, { volume: 1 });
  const [play, { stop }] = useSound(soundBG, { volume: 0.6 });

  const toggleSound = () => {
    setSound(!sound);
  };

  /*   useEffect(() => {
    playPop();


    console.log(location.pathname)
  }, [location]);

  useEffect(() => {
    
    if(showIntro === false) {
      play()
    } 

    console.log(showIntro)

    return() => {
      stop();  
    }

  }, [showIntro]);
 */

  return (
    <div className="app">
      <div className="row g-0">
        <div className="sidebar text-center">
          <Link to={"/games"}>
            <img className="logo mt-0 mt-md-5" src={imgLogo} />
          </Link>
          <ImgSidebar className="bg-sidebar d-none d-md-block" />
          <ImgSidebarMobile className="d-md-none bg-sidebar-mobile" />
        </div>
        <div className="main d-flex align-items-center">
          <div className="position-relative">
            <AnimatePresence exitBeforeEnter>
              <Routes location={location} key={location.pathname}>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/" element={<Games />} />
                <Route
                  path="games"
                  element={isAuthenticated ? <Games /> : <Navigate to="/" />}
                />

                <Route
                  path="games/:game"
                  element={isAuthenticated ? <Game /> : <Navigate to="/" />}>
                  <Route
                    path="intro"
                    element={isAuthenticated ? <Intro /> : <Navigate to="/" />}
                  />
                </Route>
                <Route
                  path="games/:game/leaderboard"
                  element={
                    isAuthenticated ? <Leaderboard /> : <Navigate to="/" />
                  }
                />
                <Route path="picnic" element={<Picnic />} />

                <Route
                  path="games/:game/users"
                  element={isAuthenticated ? <Users /> : <Navigate to="/" />}
                />
              </Routes>
            </AnimatePresence>
          </div>
        </div>
      </div>

      <div className="footer d-none">
        <div className="d-flex justify-content-evenly align-items-center">
          <div>
            <a href="#">LINK</a>
          </div>
          <div>
            <a href="#">LINK</a>
          </div>
          <div>
            <a href="https://lidl.si" target="_blank">
              <img src={imgLogoLidl} />
            </a>
          </div>
          <div>
            <a href="#">LINK</a>
          </div>
          <div>
            <a href="#">LINK</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
