import React, { useState, useEffect } from "react";
import Axios from "axios";

//import {genDeck} from './';

import Card from "../games/MemoryGame/Card";

function useGame(bg) {
  const [data, setData] = useState([]);
  const [deck, setDeck] = useState(genDeck());
  const [errors, setErrors] = useState(0);
  const [matches, setMatch] = useState([]);
  const [flipping, setFlip] = useState({ i: [], v: [] });
  const [isLoading, setIsLoading] = useState(false);



  let dataArr = ["24urnosvetovanje_a", "24urnosvetovanje_b","brkatinovember_a","brkatinovember_b","danambasadorjevzdravja_a","danambasadorjevzdravja_b","ljubljanskimaraton_a","ljubljanskimaraton_b","misko_a","misko_b","rozaoktober_a","rozaoktober_b","sadnidan_a","sadnidan_b","slovenskizajtrk_a","slovenskizajtrk_b"]


/*   let dataArr = [
    "24urnosvetovanje_a",
    "24urnosvetovanje_b",
    "brkatinovember_a",
    "brkatinovember_b",
  ]; */

  useEffect(() => {
    if (flipping.i.length === 2) {
      checkFlip(flipping);
    }
  }, [flipping]);

  useEffect(() => {
    setIsLoading(true);

    var arr = dataArr.map(function (i) {
      return i;
    });

    //let duplicated = arr.concat([...arr]);

    // there might be better ways of doing this
    let shuffled = arr.sort(() => Math.random() - 0.5);

    setData(shuffled);
    setIsLoading(false);
  }, []);




  function genDeck() {
    const items = [
      "https://via.placeholder.com/400x400?text=01",
      "https://via.placeholder.com/400x400?text=02",
      "https://via.placeholder.com/400x400?text=03",
      "https://via.placeholder.com/400x400?text=04",
      "https://via.placeholder.com/400x400?text=05",
      "https://via.placeholder.com/400x400?text=06", 
      "https://via.placeholder.com/400x400?text=07", 
      "https://via.placeholder.com/400x400?text=08", 
    ];

    /*
    var arr = data.map(function (i){
        return "https://escapebox.si/temp/" +i;
    })
*/

    // better than looping
    let duplicated = items.concat([...items]);
    // there might be better ways of doing this

    let shuffled = duplicated.sort(() => Math.random() - 0.5);

    return shuffled;
  }

  function checkFlip(f) {
    if (f.v[0] === f.v[1]) {
      //audioCorrect.play();
      setMatch([...matches, f.v[0]]);
      setFlip({ i: [], v: [] });
    } else {
      setErrors(() => errors + 1);

      setTimeout(() => {
        //audioWrong.play();
      }, 150);

      setTimeout(() => {
        setFlip({ i: [], v: [] });
      }, 1500);
    }
  }

  function reshuffle() {
    setFlip({ i: [], v: [] });
    setMatch([]);
    setErrors(0);
    setTimeout(() => setDeck(data), 600);
  }

  function cardClick(value, index) {
    const { i, v } = flipping;
    if (i.length < 2) {
      setFlip({ i: [...i, index], v: [...v, value] });
    }

    //audioMove.play();
  }

  const cards = data.map((value, i) => (
    <Card
      key={i}
      bg={bg}
      onClick={() => cardClick(value.substring(0, value.length - 2), i)}
      value={"https://www.escapebox.si/temp/lidlhr/" + value}
      matched={matches.includes(value.substring(0, value.length - 2))}
      flipping={flipping.i.includes(i)}
    />
  ));

  const finished = matches.length === deck.length / 2;

  return [cards, reshuffle, errors, finished, matches];
}

export default useGame;
