import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { dropIn } from "../utils/animations";
import Wrapper from "./Wrapper";

import imgWin from "../assets/images/win-solo.svg";
import imgBG from "../assets/images/bg-confeti.svg";
function Picnic() {
  useEffect(() => {
    document.body.className = "page-picnic";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <Wrapper>
      <motion.div className="">
        <img src={imgWin} />
        <h1>
          Tradicionalni
          <br />
          Lidlov piknik 2022!
        </h1>
        <p className="fw-bold text-blue mt-4">
          PoLET za 15 LET
          <br />
          Skupaj v brezskrbno poletje!
          <br />
          Lidlovke in Lidlovci, gremo v Velenje!
        </p>
        <p className="mt-4">
          Lidlov piknik bo potekal v nedeljo, 12. junija 2022, od 12. ure dalje
          v Parku Vista, Velenje. Organiziran bo za vse zaposlene in njihove
          družinske člane.
        </p>
        <p className="mt-4">
          Na Lidlovem pikniku bodo podeljene privlačne nagrade prvim petim
          znotraj vseh štirih posameznih iger in pa vsem nagrajencem, ki bodo
          izžrebani izmed vseh sodelujočih.
        </p>
        <div className="event mt-4">
          <div className="flex-column flex-md-row d-inline-flex justify-content-evenly">
            <div className="px-4 py-3 px-md-5">12. 6. 2022</div>
            <div className="px-4 py-3 px-md-5">Park Vista Velenje</div>
          </div>
        </div>
        <Link to="/">
          <button className="mt-6 btn btn-red text-white mx-1  px-3 px-md-4">
            Nazaj
          </button>
        </Link>
      </motion.div>
    </Wrapper>
  );
}

export default Picnic;
