import { useEffect, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { dropIn } from "../utils/animations";
import { Row, Col, Button, Form } from "react-bootstrap";
import Wrapper from "./Wrapper";

function Home() {
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  let navigate = useNavigate();

  const handleSubmit = async (event) => {
    const data = {
      quizId: "lidlhr",
      name: name,
      nickname: nickname,
      email: email,
    };

    event.preventDefault();
    const form = event.currentTarget;
    setLoading(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setLoading(false);
    } else {
      try {
        const response = await axios.post(
          "http://lidl-izziv.brainylab.io/api/user",
          data
        );
        setData(response.data);
        localStorage.setItem("token", '"' + response.data.token + '"');
        console.log(response);
      } catch (error) {
        console.log(error);
        localStorage.removeItem("token");
        navigate("/", { replace: true });
      } finally {
        setLoading(false);
      }
    }

    setValidated(true);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("token"))) {
      navigate("/games");
    }
  }, [data]);

  return (
    <Wrapper>
      <motion.div className="home">
        <h1 className="mb-5 h1">
          Dragi Lidlovci in Lidlovke, pozdravljeni! <br />
          Vpišite svoje podatke in vstopite v areno.
        </h1>
        <p>
          Tokijo, Peking, Komenda … to so prizorišča treh največjih
          športno-spretnostnih dogodkov lanskega in letošnjega leta. Zdaj pa
          prihajajo Lidlove digitalne igre.
        </p>
        <p className="fw-bold">
          Prijavi se in sodeluj, pokaži svoje spretnosti v različnih disciplinah
          in se poteguj za super nagrade!
          <br />
          Vpiši svoje podatke za sodelovanje
        </p>

        <Row className={`gx-5 align-items-center ${loading ? "loading" : ""}`}>
          <Col className="col-12 subscribe-form">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Control
                  required
                  type="text"
                  placeholder="Ime in priimek"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="text-secondary">
                  Prosimo vnesite ime in priimek
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formNickname">
                <Form.Control
                  required
                  type="text"
                  placeholder="Vzdevek"
                  onChange={(event) => {
                    setNickname(event.target.value);
                  }}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="text-secondary">
                  Prosimo vnesite vzdevek
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Control
                  required
                  type="email"
                  placeholder="Elektronski naslov"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="text-secondary">
                  Prosimo vnesite elektronski naslov.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-1 text-start">
                <Form.Check id="optin" type="checkbox">
                  <Form.Check.Input
                    //onChange={(e) => setTerms(e.target.checked)}
                    type="checkbox"
                    required
                  />
                  <Form.Check.Label>
                    Strinjam se s pogoji in pravili nagradne igre (Objavljeni so
                    na aplikaciji We are Lidl pod zavihkom 'Aktualno')
                  </Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    Strinjati se morate s pravili in pogoji.
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>

              <Button
                type="submit"
                className={"btn btn-primary text-blue mb-5"}>
                ZAČNI IGRO
              </Button>
            </Form>
          </Col>
        </Row>

        <Link className="d-none" to="/games">
          <button className="btn btn-primary text-blue">Začni igro</button>
        </Link>
      </motion.div>
    </Wrapper>
  );
}

export default Home;
