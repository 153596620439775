export const dropIn = {
    hidden: {
      x: "-30vh",
      opacity: 0,
    },
    visible: {
      x: "0",
      opacity: 1,
      transition: {
        duration: 0.6,
        type: "spring",
        bounce: 0.3,
  
      },
    },
    exit: {
      x: "30vh",
      opacity: 0,
      transition: {
        duration: 0.6,
        type: "spring",
        bounce: 0.3
  
      },
    },
  };
  
  
  export const fadeInOut = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
  
      },
    },
    exit: {
      opacity: 0,
    },
  };