import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showIntro: true,
  playSoundIngame: false,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    showIntroReducer: (state, action) => {
      state.showIntro = action.payload;
    }, 
    playSoundIngameReducer: (state, action) => {
      state.playSoundIngame = action.payload;
    }, 
    
  },
});

export const {
    showIntroReducer,playSoundIngameReducer
} = gameSlice.actions;

export default gameSlice.reducer;
