import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, Navigate } from "react-router-dom";
import useSound from "use-sound";
import { motion } from "framer-motion";
import { dropIn } from "../utils/animations";
import Wrapper from "./Wrapper";
import { Loader } from "./Loader";

import usp01 from "../assets/images/icon-game01.svg";
import usp02 from "../assets/images/icon-game02.svg";
import usp03 from "../assets/images/icon-game03.svg";
import usp04 from "../assets/images/icon-game04.svg";
import win from "../assets/images/win-solo.svg";

import soundBG from "../assets/sounds/game.mp3";

function Games() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [sound, setSound] = useState(false);
  const [play, { stop }] = useSound(soundBG, { volume: 0.6 });
  let navigate = useNavigate();

  const toggleSound = () => {
    setSound(!sound);
  };

  const playSound = () => {
    //toggleSound();
    //play();
  };

  const currentTime = new Date(Date.now()).getTime();

  console.log(new Date(Date.now()).getTime());

  const startTime = (game) => {
    let isoDate = game.starts;

    var date = new Date(isoDate).getTime();

    return date;
  };

  const endTime = (game) => {
    let isoDate = game.ends;

    var date = new Date(isoDate).getTime();

    return date;
  };

  // Axios

  const config = {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  };

  const getGames = async () => {
    try {
      const response = await axios.get(
        "http://lidl-izziv.brainylab.io/api/games/lidlhr",
        config
      );
      setData(response.data);
    } catch (error) {
      console.log(error);

      window.location.reload();
      localStorage.removeItem("token");
      <Navigate to="/" />;
    } finally {
      setLoading(false);
    }
  };

  // useEffects

  useEffect(() => {
    document.body.className = "page";
    //getGames();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Wrapper>
          <motion.div className="games">
            <h1>Lidlov digitalni izziv</h1>
            <p className="fw-bold">
              Trije tedni Lidlovih digitalnih izzivov, štiri discipline, štiri
              priložnosti za sodelovanje.
            </p>
            <p>
              Pokaži svoje veščine v štirih digitalnih disciplinah. Ti bo uspelo
              rešiti vse? Več iger, ki jih uspešno rešiš, več možnosti za končno
              nagrado imaš.
            </p>

            <div
              className={`item words rounded d-flex justify-content-evenly align-items-center mt-2 p-4 `}>
              <div>
                <img src={usp01} />
              </div>
              <div className="ps-3 flex-fill text-start">
                <h2 className="mb-0">Križanka Lidlovih vrednot</h2>
                <p className="mb-0"> 27. 5. - 30. 5.</p>
              </div>
              <div>
                <Link to="/games/words">
                  <button
                    onClick={playSound}
                    className="btn btn-red text-white mb-2 mb-md-0 mx-1  px-3 px-md-4">
                    Igraj
                  </button>
                </Link>
                <Link to="/games/words/leaderboard">
                  <button className="btn btn-outline-red  mx-1 px-3 px-md-4">
                    Lestvica
                  </button>
                </Link>
              </div>
            </div>

            <div
              className={`item flags rounded d-flex justify-content-evenly align-items-center mt-2 p-4 `}>
              <div>
                <img src={usp02} />
              </div>
              <div className="ps-3 flex-fill text-start">
                <h2 className="mb-0">
                  Zastave Lidlovih ugodnosti za zaposlene
                </h2>
                <p className="mb-0">31. 5. – 3. 6. </p>
              </div>
              <div>
                <Link to="/games/flags">
                  <button className="btn btn-yellow2 text-white mb-2 mb-md-0 mx-1  px-3 px-md-4">
                    Igraj
                  </button>
                </Link>
                <Link to="/games/flags/leaderboard">
                  <button className="btn btn-outline-yellow2  mx-1 px-3 px-md-4">
                    Lestvica
                  </button>
                </Link>
              </div>
            </div>

            <div
              className={`item quiz rounded d-flex justify-content-evenly align-items-center mt-2 p-4 `}>
              <div>
                <img src={usp03} />
              </div>
              <div className="ps-3 flex-fill text-start">
                <h2 className="mb-0">Ustvarjamo boljši svet</h2>
                <p className="mb-0">4. 6. - 6. 6.</p>
              </div>
              <div>
                <Link to="/games/quiz">
                  <button className="btn btn-blue2 text-white mb-2 mb-md-0 mx-1 px-3 px-md-4">
                    Igraj
                  </button>
                </Link>
                <Link to="/games/quiz/leaderboard">
                  <button className="btn btn-outline-blue2  mx-1 px-3 px-md-4">
                    Lestvica
                  </button>
                </Link>
              </div>
            </div>

            <div
              className={`item tower rounded d-flex justify-content-evenly align-items-center mt-2 p-4 `}>
              <div>
                <img src={usp04} />
              </div>
              <div className="ps-3 flex-fill text-start">
                <h2 className="mb-0">Spomin Promocije zdravja</h2>
                <p className="mb-0">7. 6. - 10. 6.</p>
              </div>
              <div>
                <Link to="/games/memory">
                  <button className="btn btn-pinkish text-white mb-2 mb-md-0 mx-1 px-3 px-md-4">
                    Igraj
                  </button>
                </Link>
                <Link to="/games/memory/leaderboard">
                  <button className="btn btn-outline-pinkish  mx-1 px-3 px-md-4">
                    Lestvica
                  </button>
                </Link>
              </div>
            </div>

            <div className="item piknik rounded d-flex justify-content-evenly align-items-center mt-2 p-4">
              <div>
                <img src={win} />
              </div>
              <div className="ps-3 flex-fill text-start">
                <h2 className="mb-0">Tradicionalni Lidlov piknik 2022!</h2>
                <p className="mb-0">12. 6.</p>
              </div>
              <div>
                <Link to="/picnic">
                  <button className="btn btn-outline-blue  mx-1  px-3 px-md-4">
                    Več
                  </button>
                </Link>
              </div>
            </div>
          </motion.div>
        </Wrapper>
      )}
    </>
  );
}

export default Games;
